.chat-online {
    color: #34ce57;
}

.chat-offline {
    color: #e4606d;
}
.create-new-message {
    position: relative;
    z-index: 10;
}

.btn-add-new-message {
    opacity: 0.2;
    transition: 0.5s;
}
.btn-add-new-message:hover {
    opacity: 0.7;
}

.cot-trai {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-right: 3px;
}
.cot-trai::-webkit-scrollbar-track,
.chat-messages::-webkit-scrollbar-track,
.list-people-search-new-message::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: #f5f5f5;
}

.cot-trai::-webkit-scrollbar,
.chat-messages::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
}
.list-people-search-new-message::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
}
.cot-trai::-webkit-scrollbar-thumb,
.chat-messages::-webkit-scrollbar-thumb {
    background-color: #8eabb4;
    border: 2px solid #8eabb4;
}
.list-people-search-new-message::-webkit-scrollbar-thumb {
    background-color: #4f585b;
    border: 2px solid #353b3d;
}

.chat-message-left,
.chat-message-right {
    display: flex;
    flex-shrink: 0;
}
.list-group-item.list-group-item-action.active.hidden {
    opacity: 0;
    visibility: hidden;
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
}
.list-group-item.list-group-item-action.active.show {
    opacity: 1;
    visibility: visible;
    height: auto;
    width: auto;
}
.remove-result-people svg {
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
    transform: translateY(-5px);
    font-size: 18px;
    color: #dee2e6;
}
.result-people:hover .remove-result-people svg {
    opacity: 1;
    visibility: visible;
    height: auto;
    width: auto;
}
.list-people-search-new-message {
    max-height: 300px;
    overflow-y: auto;
    max-width: 250px;
    overflow-x: hidden;
}

.chat-message-left {
    margin-right: auto;
}

.chat-message-right {
    flex-direction: row-reverse;
    margin-left: auto;
}
.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}
.flex-grow-0 {
    flex-grow: 0 !important;
}
.border-top {
    border-top: 1px solid #dee2e6 !important;
}

.chat-message {
    margin-top: 3px;
    color: white;
    position: relative;
}
.chat-message .chat-element {
    max-width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
}
.chat-message.chat-message-left {
    max-width: 60%;
}
.chat-message.chat-message-left .chat-element {
    word-wrap: break-word;
    overflow-wrap: break-word;
}
.chat-message .message-time {
    color: rgb(114, 114, 114);
    font-size: 13px;
    margin-top: 7px;
    margin-right: 3px;
    width: auto;
}
.chat-message.chat-message-left {
    padding: 6px 0;
}

.sentBy-name {
    color: #333;
    opacity: 0.7;
    font-size: 13px;
    position: absolute;
    top: -12px;
    left: 35px;
}
.col-left {
    padding: 10px 10px;
}
.col-left.active {
    background-color: #4670b9;
}
.dark .col-left.active {
    background-color: #252f3c;
}

.Ko-nghi-ra-ten {
    width: 100%;
    height: 100%;
    z-index: 2;
}
.col-left.active .Ko-nghi-ra-ten {
    color: white;
}
.last-mess {
    font-size: 15px;
    color: rgb(150, 150, 150);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 90%;
}
.dark .col-left.active .last-mess {
    color: #ffffff70;
}
.col-left.active .last-mess {
    color: #dee2e6;
}

.list-people-search-new-message {
    position: absolute;
    border-radius: 7px;
    width: 300px;
}
.list-people-search-new-message .ant-list-item h4 .d-flex {
    color: white;
}
.list-people-search-new-message .ant-list-item {
    color: white;
    padding: 5px;
    margin-bottom: 3px;
    background-color: #8ba1a1;

    transition: 0.5s;
}
.list-people-search-new-message .ant-list-item:hover {
    background-color: #8eabb4;
}

.to,
.result {
    line-height: 38px;
    margin-right: 5px;
}

.col-left.active strong {
    color: white;
}
